<template>
  <div class="app-container common-frame-component">
    <iframe
      class="frame-container"
      v-if="frameShow"
      :src="url"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { getUrlParams } from "@/utils/utils.js";
export default {
  name: "commonFrameComponent",
  data() {
    return {
      url: "",
      frameShow: false,
    };
  },
  watch: {
    "$route.query": {
      handler() {
        this.setFrameUrl();
      },
      immediate: true,
    },
  },
  created() {
    this.setFrameUrl();
  },
  methods: {
    async setFrameUrl() {
      this.frameShow = false;
      await this.$nextTick();
      const urlParams = getUrlParams();
      // const urlParams = this.$route.query;
      console.log("urlParams", urlParams);
      console.log("targetUrl", decodeURIComponent(urlParams.targetUrl));
      if (
        urlParams.targetUrl.includes("?") ||
        urlParams.targetUrl.includes("%3F")
      ) {
        this.url = `${decodeURIComponent(urlParams.targetUrl)}&auth-token=${
          urlParams["auth-token"]
        }`;
      } else {
        this.url = `${decodeURIComponent(urlParams.targetUrl)}?auth-token=${
          urlParams["auth-token"]
        }`;
      }
      this.frameShow = true;
    },
  },
};
</script>

<style lang="less" scoped>
.common-frame-component {
  overflow-y: hidden;
  .frame-container {
    height: 100%;
    width: 100%;
  }
}
</style>