var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container common-frame-component" }, [
    _vm.frameShow
      ? _c("iframe", {
          staticClass: "frame-container",
          attrs: { src: _vm.url, frameborder: "0" },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }